import { LanguageType, UserModel } from 'api-hooks/auth';
import { TokenResultModel } from 'common/auth/api';

export enum BridgeMessageType {
  Echo = 'echo',
  Notify = 'notify',
  ShareReferralCode = 'share-referral-code',
  OpenSettings = 'open-settings',
  ExternalLinks = 'external-links',
  DownloadFile = 'download-file',
  SyncToken = 'token',
  SyncMe = 'me',
  Logout = 'logout',
  SyncOneSignal = 'sync-onesignal',

  NotificationRequest = 'permissions:request:notification',
  NotificationResponse = 'permissions:response:notification',

  AppTrackingPermissionRequest = 'permissions:request:app-tracking',
  AppTrackingPermissionResponse = 'permissions:response:app-tracking',

  DirectInstallEsimRequest = 'direct-install-esim-request',
  DirectInstallEsimResponse = 'direct-install-esim-response',

  language = 'language',

  AppleLoginRequest = 'apple-login-request',
  AppleLoginResponse = 'apple-login-response',

  GoogleLoginRequest = 'google-login-request',
  GoogleLoginResponse = 'google-login-response',

  LiveChat = 'live-chat',
  Whatsapp = 'whatsapp',
  CopyclipboardRequest = 'copy-clipboard-request',
  CopyclipboardResponse = 'copy-clipboard-response',

  KurosimWebNavigation = 'kurosim-web-navigation',
  KurosimWebLoaded = 'kurosim-web-loaded',
  KurosimTabNavigation = 'kurosim-tab-navigation',
  KurosimTabNavigationLoaded = 'kurosim-tab-navigation-loaded',
  KurosimNativeLanguage = 'kurosim-native-language',
  KurosimShowNativeOverlay = 'kurosim-show-native-overlay',
}

export type KurosimTabNavigationType =
  | 'home'
  | 'orders'
  | 'my-sim'
  | 'referral'
  | 'profile';

export enum PermissionStatusEnum {
  UNAVAILABLE = 'unavailable',
  BLOCKED = 'blocked',
  DENIED = 'denied',
  GRANTED = 'granted',
  LIMITED = 'limited',
}

export enum DirectInstallResponseType {
  Success = 'success',
  Error = 'error',
}

export enum OauthStatusEnum {
  success = 'success',
  error = 'error',
}

export type OauthSuccessResultType = {
  type: OauthStatusEnum.success;
  provider: 'apple' | 'google';
  familyName: string | null;
  givenName: string | null;
  idToken: string;
};

export type OauthFailedResultType = {
  type: OauthStatusEnum.error;
  provider: 'apple' | 'google';
  message: string;
};

export type OauthResultType = OauthFailedResultType | OauthSuccessResultType;

export type CustomBridgeMessageParams = {
  [BridgeMessageType.Echo]: string;
  [BridgeMessageType.Notify]: string;
  [BridgeMessageType.ShareReferralCode]: string;
  [BridgeMessageType.AppTrackingPermissionRequest]: null;
  [BridgeMessageType.AppTrackingPermissionResponse]: boolean;
  [BridgeMessageType.NotificationRequest]: null;
  [BridgeMessageType.NotificationResponse]: PermissionStatusEnum;
  [BridgeMessageType.SyncToken]: TokenResultModel | null;
  [BridgeMessageType.SyncMe]: UserModel | null;
  [BridgeMessageType.OpenSettings]: null;
  [BridgeMessageType.ExternalLinks]: string;
  [BridgeMessageType.DownloadFile]: {
    file: any;
    type: string;
    title: string;
  };
  [BridgeMessageType.DirectInstallEsimRequest]: {
    activationCode: string;
    address: string;
    iccid: string;
  };
  [BridgeMessageType.DirectInstallEsimResponse]: {
    type: DirectInstallResponseType;
    message: string;
  };
  [BridgeMessageType.Logout]: null;
  [BridgeMessageType.SyncOneSignal]: {
    userId: string;
  };
  [BridgeMessageType.language]: string;
  [BridgeMessageType.GoogleLoginRequest]: null;
  [BridgeMessageType.GoogleLoginResponse]: OauthResultType;
  [BridgeMessageType.AppleLoginRequest]: null;
  [BridgeMessageType.AppleLoginResponse]: OauthResultType;
  [BridgeMessageType.LiveChat]: {
    email?: string;
    nickname: string;
    crispTokenId: string;
  };
  [BridgeMessageType.CopyclipboardRequest]: string;
  [BridgeMessageType.CopyclipboardResponse]: null;
  [BridgeMessageType.KurosimWebNavigation]: string;
  [BridgeMessageType.KurosimWebLoaded]: null;
  [BridgeMessageType.KurosimTabNavigation]: KurosimTabNavigationType;
  [BridgeMessageType.KurosimTabNavigationLoaded]: KurosimTabNavigationType | null;
  [BridgeMessageType.KurosimNativeLanguage]: LanguageType;
  [BridgeMessageType.KurosimShowNativeOverlay]: boolean;
};

export type UnspecifiedBridgeMessageParams = {
  [key in Exclude<
    BridgeMessageType,
    keyof CustomBridgeMessageParams
  >]: undefined;
};
export type BridgeMessageParams = CustomBridgeMessageParams &
  UnspecifiedBridgeMessageParams;

import { useUpdateSettings } from 'api-hooks/account';
import { LanguageType } from 'api-hooks/auth';
import { queryClient } from 'common/api/query-client';
import { SessionToken } from 'common/auth';
import { LocalStorageKeys } from 'common/constants/browser-storage-keys';
import notification from 'common/helpers/notification';
import { NavigationRoutes } from 'common/routes';
import useNativeBridge from 'common/routes/bridge';
import { BridgeMessageType } from 'common/routes/bridge-types';
import { useRouter } from 'next/router';
import React from 'react';

import useDetectDevice from './use-detect-device';
import useAuth from '../common/auth/use-auth';

export function getLanguageStorage(): LanguageType | undefined {
  if (typeof window === 'undefined') return undefined;
  const result = localStorage.getItem(
    LocalStorageKeys.Language,
  ) as LanguageType | null;
  return result || undefined;
}

export function setLanguageStorage(value: LanguageType) {
  if (typeof window === 'undefined') return;
  localStorage.setItem(LocalStorageKeys.Language, value);
}

interface LanguageContextProps {
  language: LanguageType;
  handleLanguage: (value: LanguageType) => void;
}

export const LanguageContext = React.createContext<LanguageContextProps>({
  language: 'en',
  handleLanguage: () => {},
});

export function LanguageProvider({ children }) {
  const firstTime = React.useRef(false);
  const prefetchFirstTime = React.useRef(false);
  const [language, setLanguage] = React.useState<LanguageType>('en');
  const { data: me } = useAuth();
  const { mutateAsync: updateSettingAsync } = useUpdateSettings();
  const { asPath, replace, pathname, locale, prefetch, isReady } = useRouter();
  const { isKurosimApp } = useDetectDevice();

  const send = useNativeBridge({
    handlers: {},
  });

  const meLanguage = me?.data?.settings?.language;

  const handleLanguage = React.useCallback(
    async (value: LanguageType) => {
      setLanguage(value);
      setLanguageStorage(value);
      replace(asPath, asPath, {
        locale: value,
      });
      // to native
      send?.({
        data: value,
        type: BridgeMessageType.language,
      });

      // when no authenticated
      if (typeof SessionToken.get() === 'undefined' && !me) {
        queryClient.invalidateQueries();
        return;
      }

      // when api data and value same
      if (meLanguage === value) {
        return;
      }
      // api fetching
      try {
        await updateSettingAsync({
          language: value,
        });
        // result.message &&
        //   notification.success({
        //     message: result.message,
        //   });
      } catch (e) {
        console.error(e);
        notification.error({
          message: e.message,
        });
      } finally {
        queryClient.invalidateQueries();
      }
    },
    [asPath, me, meLanguage, replace, send, updateSettingAsync],
  );

  // priority (set language based on me)
  React.useEffect(() => {
    if (typeof window === 'undefined') return; // skip when window not defined
    if (!isReady) return; // skip when route not ready
    if (!SessionToken.get()) return; // skip when no token
    if (typeof meLanguage === 'undefined') return; // skip when data not ready
    if (locale === meLanguage && language === meLanguage) return; // when me data sync with everything
    handleLanguage(meLanguage); // change language based on me
    firstTime.current = true;
  }, [handleLanguage, isReady, language, locale, meLanguage]);

  // priority (set language based on locale)
  React.useEffect(() => {
    if (typeof window === 'undefined') return; // skip when window not defined
    if (!isReady) return; // skip when route is not ready
    if (firstTime.current) return; // skip when first time rendered already
    if (pathname === NavigationRoutes.NotFound) return; // skip when 404 page
    if (!!SessionToken.get() && typeof meLanguage === 'undefined') return; // skip when data is not ready
    if (typeof meLanguage === 'string') return; // when data ready then skip because language was handled at top
    if (language === locale && language === getLanguageStorage()) return; // skip when everything has sync

    async function exec() {
      // when logged in
      const language = locale || 'en';
      await handleLanguage(language as any);
    }
    exec();

    firstTime.current = true;
  }, [
    handleLanguage,
    isKurosimApp,
    isReady,
    language,
    locale,
    meLanguage,
    pathname,
  ]);

  // prefetch first time
  React.useEffect(() => {
    if (prefetchFirstTime.current) return;
    Promise.all(
      Object.keys(NavigationRoutes).map((key) => {
        return prefetch(NavigationRoutes[key]);
      }),
    );
    prefetchFirstTime.current = true;
  }, [prefetch]);

  return (
    <LanguageContext.Provider value={{ language, handleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}

export default function useLanguage() {
  const context = React.useContext(LanguageContext);
  return context;
}

import React from 'react';

export default function useTimer(_second = 60): [
  number,
  {
    reset: () => void;
    set: (set: number) => void;
  },
] {
  const [second, setSecond] = React.useState(_second);

  const handler = React.useMemo(() => {
    const reset = () => setSecond(_second);
    const set = (set: number) => setSecond(set);

    return { reset, set };
  }, [_second]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setSecond((prev) => (prev <= 0 ? 0 : prev - 1));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return [second, handler];
}

interface UseSingletonTimeoutReturn {
  setInterval(cb: () => void, interval: number): void;
  setTimeout(cb: () => void, timeout: number): void;
  clearInterval(): void;
  clearTimeout(): void;
}

export function useSingletonTimeout(): UseSingletonTimeoutReturn {
  const timeoutRef = React.useRef<NodeJS.Timeout | undefined>(undefined);
  const intervalRef = React.useRef<NodeJS.Timeout | undefined>(undefined);
  React.useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
      clearInterval(timeoutRef.current);
    };
  }, []);
  return {
    setInterval: React.useCallback((cb: () => void, interval: number) => {
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(cb, interval);
    }, []),
    clearInterval: React.useCallback(() => {
      clearInterval(intervalRef.current);
    }, []),
    setTimeout: React.useCallback((cb: () => void, timeout: number) => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(cb, timeout);
    }, []),
    clearTimeout: React.useCallback(() => {
      clearInterval(intervalRef.current);
    }, []),
  };
}

interface PollingLimitProps {
  /** How many times has the polling callback been called? */
  times: number;
  /** How many milliseconds have passed since polling started? */
  millis: number;
}
interface UsePollingProps {
  /** How many times can the callback be invoked? If this is a callback, it should return a boolean that indicates whether the polling should continue or not. */
  limit?:
    | Partial<PollingLimitProps>
    | ((constraint: PollingLimitProps) => boolean);
  /** Interval (in millis) between polling invocations */
  interval: number;
  fn(): void;
  /** If key is changed, the polling will be re-registered. We don't want to use fn as the useEffect dependency. */
  key?: any;
}

export function usePolling(props: UsePollingProps) {
  const { fn, interval, limit, key } = props;
  const { setInterval, clearInterval } = useSingletonTimeout();
  const startTime = React.useRef(Date.now());
  const times = React.useRef(0);

  React.useEffect(() => {
    startTime.current = Date.now();
    times.current = 0;
    setInterval(() => {
      fn();
      times.current++;
      if (limit == null) {
        return;
      }

      const millis = Date.now() - startTime.current;
      let canContinue = false;
      if (typeof limit === 'function') {
        canContinue = limit({
          millis,
          times: times.current,
        });
      } else {
        const beyondTimeLimit = limit.millis != null && millis >= limit.millis;
        const beyondInvocationLimit =
          limit.times != null && times.current >= limit.times;
        canContinue = !beyondTimeLimit && !beyondInvocationLimit;
      }

      if (!canContinue) {
        clearInterval();
      }
    }, interval);
    return clearInterval;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);
}
